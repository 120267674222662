export class NgSellyBaseConfig {
  operatorLoginUrl: string = null;
  liquidatoreLoginUrl: string = null;
  // selectPageUrl: string = null;
  apiServer: string = null;
  apiAdminServer: string = null;
  features: string[] = [];
  btcProductNode: string = null;
  googleApiKey: string = null;
  publicKey: string = null;
  apiReports: string = null;
  apiRegister: string = null;


  constructor() {
    this.btcProductNode = '9000';
    this.googleApiKey = 'AIzaSyCcq1YVwHtOCCcJpweG-nRw4nmzqWHZ3mE';
    this.publicKey = `-----BEGIN PUBLIC KEY-----
MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgG4WgDYvFmG01LY6yOvC44WRg6m/
uhRR+tfr2rVF7sS1+CXVZ2Ye3nFiF8NiWLTWgFt7UqY3EcHk80OeKjJFI3XEnUB2
KlBTdzsYojoKof9EXXYbaNMrIVGmXWaukis9WaDrDGRJFSuMpQx5HmNAFGzWtw6I
GXyIkaxEE/rBk/NLAgMBAAE=
-----END PUBLIC KEY-----`;
  }

  public config() {
    return {
      operatorLoginUrl: this.operatorLoginUrl,
      liquidatoreLoginUrl: this.liquidatoreLoginUrl,
      apiServer: this.apiServer,
      apiAdminServer: this.apiAdminServer,
      features: this.features,
      btcProductNode: this.btcProductNode,
      googleApiKey: this.googleApiKey,
      publicKey: this.publicKey,
      apiReports: this.apiReports,
      apiRegister: this.apiRegister
    };
  }
}

export enum FEATURES {
}
